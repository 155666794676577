<app-header-style-five></app-header-style-five>

<div class="page-title-area item-bg10">
    <div class="container">
        <div class="page-title-content">            
            <h2>{{ 'perforation1' | translate }}  <br>{{ 'perforation2' | translate }}</h2>
            <p>{{ 'perforationsub2' | translate }}</p>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row">
            
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <span class="sub-title">{{ 'perforationsub' | translate }}</span>
                    <h2>{{ 'perforationtitle' | translate }}</h2>
                    
                    <p>{{ 'perforationtext' | translate }}</p>
                    
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>{{ 'place_achieve' | translate }}</h5>                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>