<app-header-style-five></app-header-style-five>

<div class="page-title-area item-bg13">
    <div class="container">
        <div class="page-title-content">            
            <h2>{{ 'contact' | translate }}</h2>
        </div>
    </div>
</div>

<div class="contact-info-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>{{ 'email' | translate }}</h3>
                    <p><a href="mailto:support@igeoz.com">support@igeoz.com</a></p> <br><br><br><br><br>                
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>{{ 'location' | translate }}</h3>
                    <p><a href="hhttps://maps.app.goo.gl/TKGyXcZMPqcS5LZY7" target="_blank">Centro de Trabajo:<br>Calle Carrancho, Manzana 67, Lote 21 <br>Residencial Valle del Jaguar, Zona Country <br>Villahermosa, Tabasco. Mexico.</a></p><br>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>{{ 'call' | translate }}</h3>
                    <p><a href="tel:529936881104">(+52) 993 688 1104 </a></p>
                    <p><a href="tel:529331174619">(+52) 933 117 4619 </a></p>
                    <p><a href="tel:529993342417">(+52) 999 334 2417 </a></p><br><br>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-area pb-100">
    <div class="container">
        <!--<div class="section-title">
            <span class="sub-title">{{ 'contact' | translate }}</span>
            <h2>{{ 'contactsub' | translate }}</h2>
            <p>{{ 'contacttext' | translate }}</p>
        </div>
        <div class="contact-form">
            <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="{{ 'form_name' | translate }}">
                            <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                <div *ngIf="name.errors.required">{{ 'form_name1' | translate }}</div>                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="{{ 'form_email' | translate }}">
                            <div class="alert alert-danger" *ngIf="email.touched && !email.valid">{{ 'form_email1' | translate }}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="{{ 'form_number' | translate }}">
                            <div class="alert alert-danger" *ngIf="number.touched && !number.valid">{{ 'form_number1' | translate }}</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="subject" type="text" #subject="ngModel" class="form-control" id="subject" placeholder="{{ 'form_subject' | translate }}">
                            <div class="alert alert-danger" *ngIf="subject.touched && !subject.valid">{{ 'form_subject1' | translate }}</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="{{ 'form_message' | translate }}"></textarea>
                            <div class="alert alert-danger" *ngIf="message.touched && !name.valid">{{ 'form_message1' | translate }}</div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">{{ 'form_button' | translate }}</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>-->
    </div>
    <div class="contact-bg-image"><img src="assets/img/map.png" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1897.172076253233!2d-92.9944732!3d18.0092251!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85edd63c7a3d9147%3A0x2292b799a45db3c5!2sFraccionamiento%20Residencial%20Valle%20Del%20Jaguar!5e0!3m2!1ses-419!2smx!4v1709095171788!5m2!1ses-419!2smx"></iframe>
</div>