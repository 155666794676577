<app-header-style-five></app-header-style-five>

<div class="page-title-area item-bg7">
    <div class="container">
        <div class="page-title-content">            
            <h2>{{ 'data_integrated1' | translate }}  <br>{{ 'data_integrated2' | translate }}</h2>
            <p></p>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="about-content">
                <div class="col-lg-12 col-md-12">                                                        
                    <span class="sub-title">{{ 'data_integratedsub' | translate }}</span>                    
                </div>
            </div>
        </div>
        <div class="row">            
            <div class="about-inner-area">                
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="about-text">
                            <h3>{{ 'log_scanner' | translate }}</h3>                            
                            <ul class="features-list">
                                <li><i class='bx bx-check'></i> {{ 'log_scannermenu' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'log_scannermenu2' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'log_scannermenu3' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'log_scannermenu4' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'log_scannermenu5' | translate }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="about-text">
                            <h3>{{ 'log_experts' | translate }}</h3>                            
                            <ul class="features-list">
                                <li><i class='bx bx-check'></i> {{ 'log_expertsmenu' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'log_expertsmenu2' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'log_expertsmenu3' | translate }}</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
                <div class="row mt-30">
                    <div class="col-lg-6 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="about-text">
                            <h3>{{ 'eop' | translate }}</h3>                            
                            <ul class="features-list">
                                <li><i class='bx bx-check'></i> {{ 'eopmenu' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'eopmenu2' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'eopmenu3' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'eopmenu4' | translate }}</li>                                
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="about-text">
                            <h3>{{ 'rts' | translate }}</h3>                            
                            <ul class="features-list">
                                <li><i class='bx bx-check'></i> {{ 'rtsmenu' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'rtsmenu2' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'rtsmenu3' | translate }}</li>                                
                            </ul>
                        </div>
                    </div>

                </div>
                
                    
                
            </div>
            
            
        </div>

        <div class="row mt-40">
            <div class="about-content">
                <div class="col-lg-12 col-md-12">                    
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>{{ 'place_achieve' | translate }}</h5>                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>