<section class="gym-feedback-area">
    <div class="container">
        <div class="gym-feedback-inner bg-dark">
            <div class="section-title">
                <span class="sub-title">Testimonials</span>
                <h2>Our Clients Feedback</h2>
            </div>
            <div class="feedback-slides-two">
                <owl-carousel-o [options]="feedbackSlidesTwo">
                    <ng-template carouselSlide>
                        <div class="single-gym-feedback-box">
                            <img src="assets/img/user1.jpg" alt="image">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <div class="title">
                                <h3>John Smith</h3>
                                <span>CEO</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="single-gym-feedback-box">
                            <img src="assets/img/user2.jpg" alt="image">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <div class="title">
                                <h3>Sarah Taylor</h3>
                                <span>General Manager</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="single-gym-feedback-box">
                            <img src="assets/img/user3.jpg" alt="image">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <div class="title">
                                <h3>James Anderson</h3>
                                <span>Business Analyst</span>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>