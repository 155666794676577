<div class="container bg-color">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <div class="section-title text-start">
                <h2>{{ 'our_goals' | translate }}</h2>
            </div>
        </div>
        <div class="col-lg-8 col-md-12">
            <div class="story-content">
                <h3>{{ 'mission' | translate }}</h3>
                <p>{{ 'missiontext' | translate }}</p>
                <h3>{{ 'vission' | translate }}</h3>
                <p>{{ 'vissiontext' | translate }}</p>                
            </div>
        </div>
    </div>
</div>