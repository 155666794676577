import { Component, OnInit } from '@angular/core';
import { ImagenService } from './imagen.service';

@Component({
  selector: 'app-why-choose-us-two',
  templateUrl: './why-choose-us-two.component.html',
  styleUrls: ['./why-choose-us-two.component.scss']
})
export class WhyChooseUsTwoComponent implements OnInit {

  rutaImagen: string = 'assets/img/about/design_thinking.svg';

  constructor(private imagenService: ImagenService) {}

  ngOnInit() {
    this.imagenService.rutaImagenActual.subscribe((nuevaRuta) => {
      this.rutaImagen = nuevaRuta;
    });
  
  }
}
