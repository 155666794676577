<div class="partner-slides">
    <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="javascript:void(0)" class="d-block">
                    <img src="assets/img/partner/partner1.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="javascript:void(0)" class="d-block">
                    <img src="assets/img/partner/partner2.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="javascript:void(0)" class="d-block">
                    <img src="assets/img/partner/partner3.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="javascript:void(0)" class="d-block">
                    <img src="assets/img/partner/partner4.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="javascript:void(0)" class="d-block">
                    <img src="assets/img/partner/partner5.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="javascript:void(0)" class="d-block">
                    <img src="assets/img/partner/partner6.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="javascript:void(0)" class="d-block">
                    <img src="assets/img/partner/partner7.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="javascript:void(0)" class="d-block">
                    <img src="assets/img/partner/partner8.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="javascript:void(0)" class="d-block">
                    <img src="assets/img/partner/partner9.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="javascript:void(0)" class="d-block">
                    <img src="assets/img/partner/partner10.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="javascript:void(0)" class="d-block">
                    <img src="assets/img/partner/partner13.png" alt="image">
                </a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-partner-item">
                <a href="javascript:void(0)" class="d-block">
                    <img src="assets/img/partner/partner14.png" alt="image">
                </a>
            </div>
        </ng-template>
    </owl-carousel-o>
    
</div>