<div class="row">
    <div class="col-2" style="width: 20%;">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bxs-group'></i>
            </div>
            <h3><span [countUp]="4500">00</span></h3>
            <p>{{ 'consultory_hours' | translate }}</p>
        </div>
    </div>
    <div class="col-2" style="width: 20%;">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bx-book-reader'></i>
            </div>
            <h3><span [countUp]="1">00</span></h3>
            <p>{{ 'locations' | translate }}</p>
        </div>
    </div>    
    <div class="col-2" style="width: 20%;">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bx-world'></i>
            </div>
            <h3><span [countUp]="3">00</span></h3>
            <p>{{ 'country' | translate }}</p>
        </div>
    </div>    
    <div class="col-2" style="width: 20%;">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bx-user-pin'></i>
            </div>
            <h3><span [countUp]="25">00</span></h3>
            <p>{{ 'partners' | translate }}</p>
        </div>
    </div>
    <div class="col-2" style="width: 20%;">
        <div class="single-funfact">
            <div class="icon">
                <i class='bx bxl-deviantart'></i>
            </div>
            <h3><span [countUp]="4">00</span></h3>
            <p>{{ 'alliances' | translate }}</p>
        </div>
    </div>
</div>