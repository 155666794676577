<app-header-style-five></app-header-style-five>
<div class="page-title-area item-bg8">
    <div class="container">
        <div class="page-title-content">            
            <h2>{{ 'gts1' | translate }}  <br>{{ 'gts2' | translate }}</h2>
            <p></p>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="about-content">
                <div class="col-lg-12 col-md-12">                                                        
                    <span class="sub-title">{{ 'gtssub' | translate }}</span>                    
                </div>
            </div>
        </div>
        <div class="row">            
            <div class="about-inner-area">                
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="about-text">
                            <h3>{{ 'reservoir_consulting' | translate }}</h3>                            
                            <ul class="features-list">
                                <li><i class='bx bx-check'></i> {{ 'reservoir_consultingmenu' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'reservoir_consultingmenu2' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'reservoir_consultingmenu3' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'reservoir_consultingmenu4' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'reservoir_consultingmenu5' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'reservoir_consultingmenu6' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'reservoir_consultingmenu7' | translate }}</li>
                                <li><i class='bx bx-check'></i> {{ 'reservoir_consultingmenu8' | translate }}</li>                                
                                
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="about-text">
                            <h3>{{ 'rci' | translate }}</h3>                            
                            <ul class="features-list">
                                <li><i class='bx bx-check'></i> {{ 'rcimenu' | translate }}</li>
                            </ul>
                        </div>
                    </div>                    
                </div>                                                    
            </div>                        
        </div>

        <div class="row mt-40">
            <div class="about-content">
                <div class="col-lg-12 col-md-12">                    
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>{{ 'place_achieve' | translate }}</h5>                        
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>