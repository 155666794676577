<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <div class="section-title text-start">
                <h2>{{ 'our_values' | translate }}</h2>
            </div>
        </div>
        <div class="col-lg-8 col-md-12">
            <div class="values-content">
                <div class="mb-30">
                    <img src="/assets/img/Integridad.png" width="50px">
                <div>
                    <h3>{{ 'integrity' | translate }}</h3>
                    <p>{{ 'integritytext' | translate }}</p>
                </div>
                </div>
                
                <div class="mb-30">
                    <img src="/assets/img/Excelencia.png" width="50px">
                <div>
                    <h3>{{ 'excellence' | translate }}</h3>
                    <p>{{ 'excellencetext' | translate }}</p>
                </div>
                </div>

                <div class="mb-30">
                    <img src="/assets/img/Seguridad.png" width="50px">
                <div>
                    <h3>{{ 'safety' | translate }}</h3>
                    <p>{{ 'safetytext' | translate }}</p>
                </div>
                </div>

                <div class="mb-30">
                    <img src="/assets/img/Equipo.png" width="50px">
                <div>
                    <h3>{{ 'teamwork' | translate }}</h3>
                    <p>{{ 'teamworktext' | translate }}</p>
                </div>
                </div>

                <div class="mb-30">
                    <img src="/assets/img/Responsabilidad.png" width="50px">
                <div>
                    <h3>{{ 'responsibility' | translate }}</h3>
                    <p>{{ 'responsibilitytext' | translate }}</p>
                </div>
                </div>
                
            </div>
        </div>
    </div>
</div>