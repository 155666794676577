// imagen.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImagenService {
  private rutaImagenSource = new BehaviorSubject<string>('assets/img/about/design_thinking.svg');
  rutaImagenActual = this.rutaImagenSource.asObservable();

  cambiarRutaImagen(nuevaRuta: string) {
    this.rutaImagenSource.next(nuevaRuta);
  }
}
