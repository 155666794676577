import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewChild, ElementRef } from '@angular/core';
import { ImagenService } from 'src/app/components/common/why-choose-us-two/imagen.service';


@Component({
    selector: 'app-header-style-five',
    templateUrl: './header-style-five.component.html',
    styleUrls: ['./header-style-five.component.scss']
})
export class HeaderStyleFiveComponent implements OnInit {
    @ViewChild('idiomSelectImg', { static: false }) idiomSelectImg: ElementRef | undefined;
    @ViewChild('idiomSelectText', { static: false }) idiomSelectText: ElementRef | undefined;
    
      
    constructor(private translate: TranslateService, private imagenService: ImagenService) {
        translate.setDefaultLang('en');
        translate.use('en');
      }

    ngOnInit(): void {}

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }


    changeLanguage(lang: string) {
        
        const imageElement = this.idiomSelectImg.nativeElement as HTMLImageElement;
        const textElement = this.idiomSelectText.nativeElement as HTMLSpanElement;
        //const imageDesignElement = this.imageDesign.nativeElement as HTMLImageElement;
        // Cambiar la propiedad src de la imagen
        if(lang=='en'){
            //imageDesignElement.src = 'assets/img/about/design_thinking.gif'; 
            imageElement.src = '/assets/img/us-flag.jpg'; 
            textElement.innerHTML = "Eng <i class='bx bx-chevron-down'></i>";
            this.imagenService.cambiarRutaImagen('assets/img/design_thinking.svg');
        }
            
        else{
            //imageDesignElement.src = 'assets/img/about/design_thinking.png'; 
            imageElement.src = '/assets/img/spain-flag.jpg'; 
            textElement.innerHTML = "Spa <i class='bx bx-chevron-down'></i>";
            this.imagenService.cambiarRutaImagen('assets/img/design_thinking_es.svg');
        }
            
        this.translate.use(lang);
        this.toggleClass2();

      }
}