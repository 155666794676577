<app-header-style-six></app-header-style-six>

<div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">            
            <h2>Blog</h2>
        </div>
    </div>
</div>

<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/blog1.jpg" alt="image">
                        </a>
                        <div class="tag">
                            <a routerLink="/blog-style-1">Learning</a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <li class="post-author">
                                <img src="assets/img/user1.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                By: <a routerLink="/blog-style-1" class="d-inline-block">Steven Smith</a>
                            </li>
                            <li><a routerLink="/blog-style-1">August 30, 2019</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog" class="d-inline-block">World largest elephant toothpaste experiment in 2019</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/blog2.jpg" alt="image">
                        </a>
                        <div class="tag">
                            <a routerLink="/blog-style-1">Education</a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <li class="post-author">
                                <img src="assets/img/user2.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                By: <a routerLink="/blog-style-1" class="d-inline-block">Lina D'Souja</a>
                            </li>
                            <li><a routerLink="/blog-style-1">August 29, 2019</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog" class="d-inline-block">Most Popular Education Posts Of The Week 20-26 Aug</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/blog3.jpg" alt="image">
                        </a>
                        <div class="tag">
                            <a routerLink="/blog-style-1">Management</a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <li class="post-author">
                                <img src="assets/img/user3.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                By: <a routerLink="/blog-style-1" class="d-inline-block">David Malan</a>
                            </li>
                            <li><a routerLink="/blog-style-1">August 28, 2019</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog" class="d-inline-block">How to enhance education quality management system</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/blog4.jpg" alt="image">
                        </a>
                        <div class="tag">
                            <a routerLink="/blog-style-1">Ideas</a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <li class="post-author">
                                <img src="assets/img/user5.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                By: <a routerLink="/blog-style-1" class="d-inline-block">David Warner</a>
                            </li>
                            <li><a routerLink="/blog-style-1">August 27, 2019</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog" class="d-inline-block">Global education: Ideas for the way move forward</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/blog5.jpg" alt="image">
                        </a>
                        <div class="tag">
                            <a routerLink="/blog-style-1">Workforce</a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <li class="post-author">
                                <img src="assets/img/user6.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                By: <a routerLink="/blog-style-1" class="d-inline-block">Olivar Waise</a>
                            </li>
                            <li><a routerLink="/blog-style-1">August 26, 2019</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog" class="d-inline-block">New report reimagines the broader education workforce</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/blog6.jpg" alt="image">
                        </a>
                        <div class="tag">
                            <a routerLink="/blog-style-1">Education</a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <li class="post-author">
                                <img src="assets/img/user2.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                By: <a routerLink="/blog-style-1" class="d-inline-block">Lina D'Souja</a>
                            </li>
                            <li><a routerLink="/blog-style-1">August 29, 2019</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog" class="d-inline-block">What’s Going On in This Picture? | Jan. 13, 2020</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/blog7.jpg" alt="image">
                        </a>
                        <div class="tag">
                            <a routerLink="/blog-style-1">Learning</a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <li class="post-author">
                                <img src="assets/img/user1.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                By: <a routerLink="/blog-style-1" class="d-inline-block">Steven Smith</a>
                            </li>
                            <li><a routerLink="/blog-style-1">August 30, 2019</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog" class="d-inline-block">Connecting Math and Science to Reading and Writing</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/blog8.jpg" alt="image">
                        </a>
                        <div class="tag">
                            <a routerLink="/blog-style-1">Education</a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <li class="post-author">
                                <img src="assets/img/user2.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                By: <a routerLink="/blog-style-1" class="d-inline-block">Lina D'Souja</a>
                            </li>
                            <li><a routerLink="/blog-style-1">August 29, 2019</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog" class="d-inline-block">How to Introduce Meditation to the High School</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post mb-30">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/blog9.jpg" alt="image">
                        </a>
                        <div class="tag">
                            <a routerLink="/blog-style-1">Management</a>
                        </div>
                    </div>
                    <div class="post-content">
                        <ul class="post-meta">
                            <li class="post-author">
                                <img src="assets/img/user3.jpg" class="d-inline-block rounded-circle mr-2" alt="image">
                                By: <a routerLink="/blog-style-1" class="d-inline-block">David Malan</a>
                            </li>
                            <li><a routerLink="/blog-style-1">August 28, 2019</a></li>
                        </ul>
                        <h3><a routerLink="/single-blog" class="d-inline-block">Tools for Creating Digital Student Portfolios</a></h3>
                        <a routerLink="/single-blog" class="read-more-btn">Read More <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/blog-style-1" class="page-numbers">2</a>
                    <a routerLink="/blog-style-1" class="page-numbers">3</a>
                    <a routerLink="/blog-style-1" class="page-numbers">4</a>
                    <a routerLink="/blog-style-1" class="page-numbers">5</a>
                    <a routerLink="/blog-style-1" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div>
        </div>
    </div>
</div>