<app-header-style-five></app-header-style-five>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">            
            <h2>{{ 'about_us' | translate }}</h2>
        </div>
    </div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/bg3-1.fw.png" class="shadow" alt="image">                    
                </div>
            </div>
            
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">{{ 'weare_igeoz' | translate }}</span>
                    <h2>{{ 'weare_igeoztitle' | translate }}</h2>
                    <h6>{{ 'weare_igeozsub' | translate }}</h6>
                    <p>{{ 'weare_igeoztext1' | translate }}.</p>
                    <p>{{ 'weare_igeoztext2' | translate }}</p>
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>{{ 'place_achieve' | translate }}</h5>                        
                    </div>
                </div>
            </div>
        </div>
        <div class="about-inner-area" style="display:none">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>100,000 online courses</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Activate Listening</li>
                            <li><i class='bx bx-check'></i> Brilliant minds</li>
                            <li><i class='bx bx-check'></i> Better. Best. Wow!</li>
                            <li><i class='bx bx-check'></i> Branding it better!</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Expert instruction</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Creating. Results.</li>
                            <li><i class='bx bx-check'></i> Expect more</li>
                            <li><i class='bx bx-check'></i> Good thinking</li>
                            <li><i class='bx bx-check'></i> In real we trust</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Lifetime access</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Stay real. Always.</li>
                            <li><i class='bx bx-check'></i> We have you covered</li>
                            <li><i class='bx bx-check'></i> We turn heads</li>
                            <li><i class='bx bx-check'></i> Your brand, promoted</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="story-area ptb-100">
    <app-our-story></app-our-story>
</div>

<div class="funfacts-area">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<div align="center">
    <img src="assets/img/about/map.gif" alt="Locations" style="max-width: 50% !important; ">
</div>

<div class="values-area ptb-100">
    <app-our-values></app-our-values>
</div>

<div class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'customers' | translate }}</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>

