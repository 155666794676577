<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>{{ 'why_igeoz' | translate }}</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/">{{ 'why_igeoztext1' | translate }}</a></li>
                        <li><a routerLink="/">{{ 'why_igeoztext2' | translate }} </a></li>                        
                    </ul>                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>{{ 'contact_us' | translate }}</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="javascript:void(0)" >Centro de Trabajo:<br>Calle Carrancho, Manzana 67, Lote 21 <br>Residencial Valle del Jaguar, Zona Country <br>Villahermosa, Tabasco. Mexico.
                                </a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:529936881104">(+52) 993 688 1104 </a><br>
                            <a href="tel:529331174619">(+52) 933 117 4619 </a><br>
                            <a href="tel:529993342417">(+52) 999 334 2417 </a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:support@igeoz.com">support@igeoz.com</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="https://www.linkedin.com/company/igeoz/" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li> 
                        <li><a href="https://youtube.com/@igeoz9717?si=HXpcYE6CHn8ie5NH" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>                                             
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>{{ 'services' | translate }}</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/data-integrated-solution">{{ 'data_integrated' | translate }}</a></li>
                        <li><a routerLink="/geoscience-technical-support">{{ 'gts' | translate }}</a></li>
                        <li><a routerLink="/data-analytics-artificial-intelligence">{{ 'da' | translate }}</a></li> 
                    </ul>
                </div>
            </div>
            <!--<div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>{{ 'newsletter' | translate }}</h3>
                    <div class="newsletter-box">
                        <p>{{ 'newsletter_text' | translate }}</p>
                        <form class="newsletter-form">
                            <label>{{ 'newsletter_email' | translate }}</label>
                            <input type="email" class="input-newsletter" placeholder="{{ 'newsletter_input' | translate }}" name="EMAIL">
                            <button type="submit">{{ 'newsletter_button' | translate }}</button>
                        </form>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/logo-blanco.svg" width="170px" alt="image"></a>
                <br><p style="font-size: 12px; font-weight: 800;">v 2.1</p>
            </div>
            
        </div>
    </div>
</footer>

<ngx-scrolltop></ngx-scrolltop>