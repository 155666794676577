<section class="blog-area bg-0b0517 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our News</span>
            <h2>Check Out Our Latest Blog</h2>
            <p>Explore our latest articles, insights, and updates. Stay informed and inspired with our thought-provoking content.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box without-bg-color">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/10.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-style-1" class="category">World</a>
                        <h3><a routerLink="/single-blog">The path to recovery for US oil production</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box without-bg-color">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/11.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-style-1" class="category">Market</a>
                        <h3><a routerLink="/single-blog">Helping the industry navigate the energy transition</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box without-bg-color">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/blog/12.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-style-1" class="category">IT</a>
                        <h3><a routerLink="/single-blog">The reality of digital in oil and gas</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>