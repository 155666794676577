<app-header-style-five></app-header-style-five>
<app-homeeleven-main-banner></app-homeeleven-main-banner>

<app-gym-features></app-gym-features>

<app-why-choose-us-two></app-why-choose-us-two>

<div class="funfacts-area">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div>

<!--<app-gym-feedback></app-gym-feedback>

<app-homeeleven-blog></app-homeeleven-blog>
-->

<div class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>{{ 'customers' | translate }}</h2>
            
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div>

<!--<app-get-started></app-get-started>-->