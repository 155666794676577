<header class="header-area p-relative">
    <div class="top-header top-header-style-four">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <ul class="top-header-contact-info">
                        <li>
                            {{ 'call' | translate }} 
                            <a href="tel:+502464674">(+52) 993 688 1104 </a>
                        </li>
                    </ul>
                    <div class="top-header-social">
                        <span>{{ 'follow' | translate }}</span>
                        <a href="https://www.linkedin.com/company/igeoz/" target="_blank"><i class='bx bxl-linkedin'></i></a>
                        <a href="https://youtube.com/@igeoz9717?si=HXpcYE6CHn8ie5NH" target="_blank"><i class='bx bxl-youtube'></i></a>                       
                    </div>
                </div>                
            </div>
        </div>
    </div>
    <div class="navbar-area navbar-style-three" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo-original.svg" width="135" alt="iGeoz"></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">{{ 'services' | translate }} <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/data-integrated-solution" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'data_integrated' | translate }}</a></li>  
                                <li class="nav-item"><a routerLink="/geoscience-technical-support" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'gts' | translate }}</a></li>
                                <li class="nav-item"><a routerLink="/data-analytics-artificial-intelligence" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'da' | translate }}</a></li>  

                                <!--
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link"><h6>{{ 'log_scanner' | translate }}</h6> <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'log_scannermenu2' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'log_scannermenu3' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'log_scannermenu4' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'log_scannermenu5' | translate }}</a></li>
                                    </ul>
                                </li>  
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link"><h6>{{ 'log_experts' | translate }}</h6> <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'log_expertsmenu' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'log_expertsmenu2' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'log_expertsmenu3' | translate }}</a></li>
                                    </ul>
                                </li>    
                                
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link"><h6>{{ 'reservoir_consulting' | translate }}</h6> <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'reservoir_consultingmenu' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'reservoir_consultingmenu2' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'reservoir_consultingmenu3' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'reservoir_consultingmenu4' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'reservoir_consultingmenu5' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'reservoir_consultingmenu6' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'reservoir_consultingmenu7' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'reservoir_consultingmenu8' | translate }}</a></li>
                                    </ul>
                                </li>    

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link"><h6>{{ 'eop' | translate }}</h6> <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'eopmenu' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'eopmenu2' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'eopmenu3' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'eopmenu4' | translate }}</a></li>
                                    </ul>
                                </li>  

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link"><h6>{{ 'rts' | translate }}</h6> <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'rtsmenu' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'rtsmenu2' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'rtsmenu3' | translate }}</a></li>
                                    </ul>
                                </li>  

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link"><h6>{{ 'da' | translate }}</h6> <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'damenu' | translate }}</a></li>
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'damenu2' | translate }}</a></li>                                        
                                    </ul>
                                </li>  

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link"><h6>{{ 'rci' | translate }}</h6> <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'rcimenu' | translate }}</a></li>                                        
                                    </ul>
                                </li> 
                                
                            -->
                            </ul>
                            
                                               
                        <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'about_us' | translate }}</a></li>                                                
                        <!--<li class="nav-item"><a href="/blog-style-1" class="nav-link" >Blog</a></li>-->
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">{{ 'contact' | translate }}</a></li>
                        
                    </ul>
                </div>  
                <div class="others-option">
                    <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied2">
                        <button #idiomSelect id="idiomSelect" class="dropdown-toggle" type="button" (click)="toggleClass2()">
                            <img #idiomSelectImg id="idiomSelectImg" src="assets/img/us-flag.jpg" class="shadow" alt="image" >
                            <span #idiomSelectText id="idiomSelectText">Eng <i class='bx bx-chevron-down'></i></span>
                        </button>
                        
                        <div class="dropdown-menu">  
                            <a href="javascript:void(0)" class="dropdown-item d-flex align-items-center" (click)="changeLanguage('en')">
                                <img src="assets/img/us-flag.jpg" class="shadow-sm" alt="flag" >
                                <span>Eng</span>
                            </a>                          
                            <a href="javascript:void(0)" class="dropdown-item d-flex align-items-center" (click)="changeLanguage('es')">
                                <img src="assets/img/spain-flag.jpg" class="shadow-sm" alt="flag" >
                                <span>Spa</span>
                            </a>                           
                        </div>
                    </div>                    
                </div>
            </nav>
        </div>
    </div> 
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>

