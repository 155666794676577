<div class="gym-home-slides">
    <owl-carousel-o [options]="gymHomeSlides">
        <ng-template carouselSlide>
            <div class="gym-main-banner bg-dark bg-1">
                <div class="container">
                    <div class="gym-banner-content">
                        
                        <h1>{{ 'data_integrated1' | translate }}<br>{{ 'data_integrated2' | translate }}</h1>
                        <p class="subtitlebanner"></p>
                        <a routerLink="/data-integrated-solution" class="default-btn"><i class='bx bx-book-content icon-arrow before'></i><span class="label">{{ 'learn_more' | translate }}</span><i class="bx bx-book-content icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="gym-main-banner bg-dark bg-2">
                <div class="container">
                    <div class="gym-banner-content">
                        
                        <h1>{{ 'gts1' | translate }}<br>{{ 'gts2' | translate }}</h1>
                        <p class="subtitlebanner"></p>
                        <a routerLink="/geoscience-technical-support" class="default-btn"><i class='bx bx-book-content icon-arrow before'></i><span class="label">{{ 'learn_more' | translate }}</span><i class="bx bx-book-content icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="gym-main-banner bg-dark bg-3">
                <div class="container">
                    <div class="gym-banner-content">
                        
                        <h1>{{ 'da1' | translate }}<br>{{ 'da2' | translate }}</h1>
                        <p class="subtitlebanner"></p>
                        <a routerLink="/data-analytics-artificial-intelligence" class="default-btn"><i class='bx bx-book-content icon-arrow before'></i><span class="label">{{ 'learn_more' | translate }}</span><i class="bx bx-book-content icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </ng-template>
        <!--<ng-template carouselSlide>
            <div class="gym-main-banner bg-dark bg-4">
                <div class="container">
                    <div class="gym-banner-content">
                        
                        <h1>{{ 'eop1' | translate }} <br>{{ 'eop2' | translate }}</h1>
                        <p class="subtitlebanner"></p>
                        <a routerLink="/perforation-design" class="default-btn"><i class='bx bx-book-content icon-arrow before'></i><span class="label">{{ 'learn_more' | translate }}</span><i class="bx bx-book-content icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="gym-main-banner bg-dark bg-5">
                <div class="container">
                    <div class="gym-banner-content">                        
                        <h1>{{ 'rts1' | translate }}<br>{{ 'rts2' | translate }}</h1>
                        <p class="subtitlebanner"></p>
                        <a routerLink="/plt-synthetic" class="default-btn"><i class='bx bx-book-content icon-arrow before'></i><span class="label">{{ 'learn_more' | translate }}</span><i class="bx bx-book-content icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="gym-main-banner bg-dark bg-6">
                <div class="container">
                    <div class="gym-banner-content">
                        
                        <h1>{{ 'da1' | translate }}<br>{{ 'da2' | translate }}</h1>
                        <p class="subtitlebanner"></p>
                        <a routerLink="/production-log-analysis" class="default-btn"><i class='bx bx-book-content icon-arrow before'></i><span class="label">{{ 'learn_more' | translate }}</span><i class="bx bx-book-content icon-arrow after"></i></a>
                    </div>
                </div>
            </div>            
        </ng-template>
        <ng-template carouselSlide>
            <div class="gym-main-banner bg-dark bg-7">
                <div class="container">
                    <div class="gym-banner-content">
                        
                        <h1>{{ 'rci1' | translate }}<br>{{ 'rci2' | translate }}<br>{{ 'rci3' | translate }}</h1>
                        <p class="subtitlebanner"></p>
                        <a routerLink="/production-log-analysis" class="default-btn"><i class='bx bx-book-content icon-arrow before'></i><span class="label">{{ 'learn_more' | translate }}</span><i class="bx bx-book-content icon-arrow after"></i></a>
                    </div>
                </div>
            </div>            
        </ng-template>-->
    </owl-carousel-o>
</div>