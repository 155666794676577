<section class="learning-area pt-100 pb-20 bg-0b0517">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">{{ 'why_us' | translate }}</span>
            <h2>{{ 'why_ustitle' | translate }}​</h2>
            <p>{{ 'why_ustext' | translate }}</p>
        </div>
        <!--<div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-learning-box">
                    <div class="icon">
                        <i class="bx bx-desktop"></i>
                    </div>
                    <h3>{{ 'feature1' | translate }}</h3>
                    <p>{{ 'feature1text' | translate }}</p>                    
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-learning-box">
                    <div class="icon">
                        <i class="bx bx-briefcase"></i>
                    </div>
                    <h3>{{ 'feature2' | translate }}</h3>
                    <p>{{ 'feature2text' | translate }}</p>                  
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-learning-box">
                    <div class="icon">
                        <i class="bx bx-bar-chart-square"></i>
                    </div>
                    <h3>{{ 'feature3' | translate }}</h3>
                    <p>{{ 'feature3text' | translate }}</p>                    
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-learning-box">
                    <div class="icon">
                        <i class="bx bx-data"></i>
                    </div>
                    <h3>{{ 'feature4' | translate }}</h3>
                    <p>{{ 'feature4text' | translate }}</p>                    
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-learning-box">
                    <div class="icon">
                        <i class="bx bx-user"></i>
                    </div>
                    <h3>{{ 'feature5' | translate }}</h3>
                    <p>{{ 'feature5text' | translate }}</p>                    
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-learning-box">
                    <div class="icon">
                        <i class="bx bx-lock"></i>
                    </div>
                    <h3>{{ 'feature6' | translate }}</h3>
                    <p>{{ 'feature6text' | translate }}</p>                     
                </div>
            </div>
        </div>-->
    </div>
</section>