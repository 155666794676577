<section class="why-choose-us-area-two ">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="why-choose-us-img">
                    <img [src]="rutaImagen" alt="image" width="50%" id="imageDesign" #imageDesign>                    
                </div>
            </div>
            <!--<div class="col-lg-6 col-md-12">
                <div class="why-choose-us-text">
                    <span class="sub-title">{{ 'design_thinking' | translate }}</span>
                    <h2>{{ 'design_thinkingtitle' | translate }}</h2>                    
                    <ul class="features-list">
                        <li><span><i class="bx bx-check-circle"></i> {{ 'apply' | translate }}</span></li>
                        <li><span><i class="bx bx-check-double"></i> {{ 'optimize' | translate }}</span></li>
                        <li><span><i class="bx bx-shield-quarter"></i> {{ 'protect' | translate }}</span></li>
                        <li><span><i class="bx bx-message-rounded-dots"></i> {{ 'idea' | translate }}</span></li>
                        <li><span><i class="bx bx-spreadsheet"></i> {{ 'dephine' | translate }}</span></li>
                        <li><span><i class="bx bx-bookmark"></i> {{ 'empatize' | translate }}</span></li>
                    </ul>                    
                </div>
            </div>-->
        </div>
    </div>
</section>