<section class="funfacts-style-three bg-dark bg-100f1f">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-funfacts-item">
                    <h3><span [countUp]="4500">00</span></h3>
                    <p>Consultory Hours</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-funfacts-item">
                    <h3><span [countUp]="7">00</span></h3>
                    <p>America Locations</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-funfacts-item">
                    <h3><span [countUp]="25">00</span></h3>
                    <p>Partners & Collegues</p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-funfacts-item">
                    <h3><span [countUp]="4">00</span>%</h3>
                    <p>Strategic Alliances</p>
                </div>
            </div>
        </div>
    </div>
</section>