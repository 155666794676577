<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our News</span>
            <h2>Our Latest Blog</h2>
            <p>Explore our latest articles, insights, and updates. Stay informed and inspired with our thought-provoking content.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box without-bg-color">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/business-coaching/blog-img1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-style-1" class="category">MBA</a>
                        <h3><a routerLink="/single-blog">Why a Blog Gets You Further than an MBA</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box without-bg-color">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/business-coaching/blog-img2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-style-1" class="category">Business</a>
                        <h3><a routerLink="/single-blog">Why Your Small Business Must Start a Blog</a></h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box without-bg-color">
                    <div class="post-image">
                        <a routerLink="/single-blog" class="d-block">
                            <img src="assets/img/business-coaching/blog-img3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-style-1" class="category">Blogger</a>
                        <h3><a routerLink="/single-blog">Become a Better Blogger Today With Us</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="business-shape8"><img src="assets/img/business-coaching/business-shape6.png" alt="image"></div>
</section>